import { useEffect, useMemo, useRef, useState } from "react";

import { Box, Button, Grid, Stack, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import ScrollX from "../../components/ScrollX";
import ReactTable from "./react-table/ReactTable";
import { usersTableDataType } from "../../types/tableTypes";
import useData from "../../hooks/useApi";
import useMessage from "../../hooks/useMessage";
import { Link } from "react-router-dom";
import { regExValidations } from "../../data/consts";
import { cellCenterAlign, cellRightAlign } from "./CellFormatters";

const BalancesTable = () => {
    const { postData } = useData();
    const { showError } = useMessage();
    const [data, setData] = useState<usersTableDataType[]>([]);
    const [pageSize, setPageSize] = useState<number>(10);
    const [pageIndex, setPageIndex] = useState<number>(0);
    const [rowCount, setRowCount] = useState<number>(0);
    const [sortBy, setSortBy] = useState<{
        id: string;
        desc: boolean;
    }>({
        id: "id",
        desc: false,
    });

    // Filters & searches
    const username = useRef("");
    const email = useRef("");
    const [usernameSearch, setUsernameSearch] = useState<string>("");
    const [emailSearch, setEmailSearch] = useState<string>("");
    const [validations, setValidations] = useState<any>({ email: true });
    const [loading, setLoading] = useState<boolean>(false);
    const [resetSwitcher, setResetSwitcher] = useState<boolean>(false);

    interface RequestType {
        page: number;
        size: number;
        filterAnd: any[];
        sort: {
            key: string;
            order: "ASC" | "DESC";
        };
    }

    function cellLink({ value }: any) {
        const row = data.find((row) => row.username === value);
        if (value && row) {
            return (
                <Link
                    style={{
                        textDecoration: "none",
                        color: "#1890FF",
                    }}
                    to={`/balances/${row.username}`}
                >
                    {value}
                </Link>
            );
        } else {
            return <></>;
        }
    }

    const searchHandler = async (isInit: boolean = false) => {
        setLoading(true);
        const userData: RequestType = {
            page: isInit ? 1 : pageIndex + 1,
            size: pageSize,
            filterAnd: [],
            sort: {
                key: sortBy.id,
                order: sortBy.desc ? "DESC" : "ASC",
            },
        };
        if (username.current !== "") {
            userData.filterAnd.push({
                key: "username",
                operation: "LIKE",
                value: `${username.current}%`,
            });
        }

        if (username.current === "" && email.current !== "") {
            userData.filterAnd.push({
                key: "email",
                operation: "=",
                value: email.current,
            });
        }
        // console.log(userData);

        try {
            const response: any = await postData(
                "/transaction/getUsersBalance",
                userData,
            );
            if (response?.data) {
                setData(response.data);
            } else {
                setData([]);
            }
            setRowCount(response?.count ? response?.count : 0);
        } catch (error) {
            showError("Data Fetching Error");
        }
        setLoading(false);
    };

    const searchButtonHandler = () => {
        if (emailSearch === "") {
            setValidations({ email: true });
        } else {
            if (emailSearch.match(regExValidations.email)) {
                setValidations({ email: true });
            } else {
                setValidations({ email: false });
                username.current = usernameSearch;
                email.current = "";
                return;
            }
        }
        username.current = usernameSearch;
        email.current = emailSearch;
        setPageIndex(0);
        searchHandler(true);
    };

    useEffect(() => {
        if (sortBy.id) searchHandler();
    }, [sortBy, pageIndex, pageSize, resetSwitcher]);

    const columns = useMemo(
        () => [
            {
                Header: "ID",
                accessor: "id",
                disableSortBy: true,
                width: "100px",
                Cell: cellCenterAlign,
            },
            {
                Header: "Username",
                accessor: "username",
                Cell: cellLink,
                disableSortBy: true,
                align: "left",
            },
            {
                Header: "Email",
                accessor: "email",
                disableSortBy: true,
                align: "left",
            },
            {
                Header: "Balance ($)",
                accessor: "current_balance",
                disableSortBy: true,
                Cell: cellRightAlign,
                align: "right",
            },
        ],
        [cellLink],
    );

    return (
        <Grid container>
            {/* Filter Section */}
            <Grid item xs={12}>
                <Box
                    sx={{
                        mb: 4,
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: { xs: "column-reverse", sm: "row" },
                        flexGrow: 1,
                        width: { xs: "100%", sm: "100%" },
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            my: 1,
                            flexGrow: 1,
                            justifyContent: { xs: "flex-end", sm: "flex-end" },
                            flexDirection: { xs: "column", sm: "row" },
                            alignItems: "center",
                            width: { xs: "100%", sm: "100%" },
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                my: 1,
                                pr: { sm: 1, xs: 0 },
                                flexGrow: 1,
                                flexDirection: { xs: "column", sm: "row" },
                                width: { xs: "100%", sm: "100%" },
                            }}
                        >
                            <TextField
                                placeholder="Username"
                                sx={{
                                    mr: { xs: 0, sm: 1 },
                                    my: { xs: 1, sm: 0 },
                                    width: { sm: 250, xs: "100%" },
                                }}
                                value={usernameSearch}
                                inputProps={{
                                    style: {
                                        padding: "10px 8px",
                                    },
                                }}
                                onKeyDown={(e) => {
                                    if (e.code == "Enter")
                                        searchButtonHandler();
                                }}
                                onChange={(e) => {
                                    setUsernameSearch(
                                        e.target.value.toLowerCase().trim(),
                                    );
                                }}
                            />
                            <TextField
                                placeholder="Email"
                                error={!validations.email}
                                helperText={
                                    !validations.email ? "Invalid Email" : ""
                                }
                                sx={{
                                    mx: { xs: 0, sm: 1 },
                                    width: { sm: 250, xs: "100%" },
                                }}
                                value={emailSearch}
                                onKeyDown={(e) => {
                                    if (e.code == "Enter")
                                        searchButtonHandler();
                                }}
                                onChange={(e) => {
                                    setEmailSearch(
                                        e.target.value.toLowerCase().trim(),
                                    );
                                }}
                                inputProps={{
                                    style: {
                                        padding: "10px 8px",
                                    },
                                }}
                            />
                        </Box>
                        <Stack
                            sx={{
                                flexDirection: { xs: "column", sm: "row" },
                                width: "100%",
                                justifyContent: "flex-end",
                                mb: validations.email ? 0 : "21px",
                            }}
                        >
                            <Button
                                variant="outlined"
                                color="error"
                                sx={{
                                    mx: { sm: 1, xs: 0 },
                                    height: "40px",
                                    width: { xs: "100%", sm: "auto" },
                                    "&:hover": {
                                        color: "#ff4d4f",
                                        borderColor: "#ff4d4f",
                                    },
                                }}
                                onClick={() => {
                                    setUsernameSearch("");
                                    setEmailSearch("");
                                    email.current = "";
                                    username.current = "";
                                    setValidations({ email: true });
                                    setResetSwitcher(
                                        (resetSwitcher) => !resetSwitcher,
                                    );
                                }}
                            >
                                Reset
                            </Button>
                            <Button
                                variant="contained"
                                endIcon={<SearchIcon />}
                                sx={{
                                    mx: { sm: 1, xs: 0 },
                                    height: "40px",
                                    my: { xs: 1, sm: 0 },
                                    width: { xs: "100%", sm: "auto" },
                                }}
                                onClick={searchButtonHandler}
                                onKeyDown={(e) => {
                                    if (e.code == "Enter")
                                        searchButtonHandler();
                                }}
                            >
                                Search
                            </Button>
                        </Stack>
                    </Box>
                </Box>
            </Grid>
            {/* Table Section */}
            <Grid item xs={12}>
                <ScrollX>
                    <ReactTable
                        pageSize={pageSize}
                        pageIndex={pageIndex}
                        columns={columns}
                        data={data}
                        setPageIndexProp={setPageIndex}
                        setPageSizeProp={setPageSize}
                        setSortByProp={setSortBy}
                        loading={loading}
                        rowCount={rowCount}
                    />
                </ScrollX>
            </Grid>
        </Grid>
    );
};

export default BalancesTable;
