// third-party
import { FormattedMessage } from "react-intl";

// assets
import {
    GlobalOutlined,
    InboxOutlined,
    SettingOutlined,
    SendOutlined,
    TeamOutlined,
    MailOutlined,
    CarryOutOutlined,
    ExclamationCircleOutlined,
} from "@ant-design/icons";

// type
import { NavItemType } from "../types/menu";
import { BsPiggyBank } from "react-icons/bs";
import { FaChalkboardUser } from "react-icons/fa6";
import { BsGlobeAsiaAustralia } from "react-icons/bs";
import { BsCoin } from "react-icons/bs";

const icons = {
    GlobalOutlined,
    InboxOutlined,
    SettingOutlined,
    SendOutlined,
    FaChalkboardUser,
    TeamOutlined,
    MailOutlined,
    CarryOutOutlined,
    BsGlobeAsiaAustralia,
    ExclamationCircleOutlined,
    BsPiggyBank,
    BsCoin,
};

const other: NavItemType = {
    id: "other",
    type: "group",
    children: [
        {
            id: "allBids",
            title: <FormattedMessage id="allBids" defaultMessage="All Bids" />,
            type: "item",
            url: "/allBids",
            icon: icons.BsGlobeAsiaAustralia,
        },
        {
            id: "allAuctions",
            title: (
                <FormattedMessage
                    id="allAuctions"
                    defaultMessage="All Auctions"
                />
            ),
            type: "item",
            url: "/allAuctions",
            icon: icons.BsCoin,
        },
        {
            id: "balances",
            title: <FormattedMessage id="balances" defaultMessage="Balances" />,
            type: "item",
            url: "/balances",
            icon: icons.BsPiggyBank,
        },
        {
            id: "admin",
            title: <FormattedMessage id="admin" defaultMessage="Admin" />,
            type: "collapse",
            url: "/admin",
            icon: icons.FaChalkboardUser,
            children: [
                {
                    id: "users",
                    title: (
                        <FormattedMessage id="users" defaultMessage="Users" />
                    ),
                    type: "item",
                    url: "/admin/users",
                    icon: icons.TeamOutlined,
                },
                {
                    id: "messages",
                    title: (
                        <FormattedMessage
                            id="messages"
                            defaultMessage="Messages"
                        />
                    ),
                    type: "item",
                    url: "/admin/messages",
                    icon: icons.SendOutlined,
                },
                {
                    id: "emails",
                    title: (
                        <FormattedMessage id="emails" defaultMessage="Emails" />
                    ),
                    type: "item",
                    url: "/admin/emails",
                    icon: icons.MailOutlined,
                },
                {
                    id: "tlds",
                    title: <FormattedMessage id="tlds" defaultMessage="TLDs" />,
                    type: "item",
                    url: "/admin/tlds",
                    icon: icons.GlobalOutlined,
                },
                {
                    id: "registries",
                    title: (
                        <FormattedMessage
                            id="registries"
                            defaultMessage="Registries"
                        />
                    ),
                    type: "item",
                    url: "/admin/registries",
                    icon: icons.CarryOutOutlined,
                },
                {
                    id: "whois",
                    title: (
                        <FormattedMessage id="whois" defaultMessage="Whois" />
                    ),
                    type: "item",
                    url: "/admin/whois",
                    icon: icons.InboxOutlined,
                },
                {
                    id: "blacklist",
                    title: (
                        <FormattedMessage
                            id="blacklist"
                            defaultMessage="Blacklist"
                        />
                    ),
                    type: "item",
                    url: "/admin/blacklist",
                    icon: icons.ExclamationCircleOutlined,
                },
            ],
        },
    ],
};

export default other;
