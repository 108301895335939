import Big from "big.js";
import MainWrapper from "../../components/wrappers/MainWrapper";
import UserTransactionsTable from "../tables/UserTransactionsTable";
import { Stack, Typography } from "@mui/material";
import { useState } from "react";

const UserTransactionsPage = () => {
    const [userBalance, setUserBalance] = useState(0);
    const [username, setUsername] = useState("");

    const Title = () => (
        <Stack direction="row">
            <Typography
                sx={{
                    fontSize: { sm: "22px", xs: "16px" },
                    fontWeight: 600,
                    color: "#3A3541CC",
                    paddingBottom: { sm: 3, xs: 2 },
                }}
            >
                Transactions of {username} :
            </Typography>
            <Typography
                sx={{
                    fontSize: { sm: "22px", xs: "16px" },
                    fontWeight: 500,
                    color: "#1890FF",
                    paddingBottom: { sm: 3, xs: 2 },
                    ml: 1,
                }}
            >
                {` $ ${new Big(userBalance).toFixed(2)}`}
            </Typography>
        </Stack>
    );

    return (
        <MainWrapper element={<Title />}>
            <UserTransactionsTable
                setUsername={setUsername}
                setUserBalance={setUserBalance}
            />
        </MainWrapper>
    );
};

export default UserTransactionsPage;
