import { Chip, Box } from "@mui/material";
import SeeMoreText from "../../components/filters/SeeMoreText";
import { BsTrash } from "react-icons/bs";
import IconButton from "../../components/@extended/IconButton";
import { Stack } from "@mui/material";
import { Link } from "react-router-dom";
import { DateTime } from "luxon";
import Big from "big.js";
import { AuctionStatusEnum } from "../../enums/AuctionStatus";
import { DomainStatusEnum } from "../../enums/DomainStatus";
import DomainTypeStatus from "../../components/common/DomainTypeStatus";

const fontSize = "12px";

const chipStyle = {
    px: 0,
    py: 0,
    height: 1,
    borderRadius: "3px",
    "&& .MuiChip-labelMedium": {
        fontSize,
        px: "6px",
        py: "3px",
    },
};

export function cellDateAndTime({ value }: any) {
    if (!value) return <></>;

    const formattedDate = new Date(value * 1000).toLocaleString("en-CA", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
    });

    return <>{formattedDate.replace(",", " ")}</>;
}

export function cellSeeMore({ value }: any) {
    if (value) {
        return (
            <div>
                <SeeMoreText text={value} chrLimit={55} />
            </div>
        );
    } else {
        return <></>;
    }
}

export function cellStatusChip({ value }: { value: string }) {
    switch (value) {
        case "info":
            return (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Chip
                        variant="light"
                        sx={{
                            bgcolor: "#E6F7FF",
                            color: "#1890FF",
                            ...chipStyle,
                            fontSize,
                        }}
                        label="Info"
                    />
                </Box>
            );
        case "danger":
            return (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Chip
                        variant="light"
                        sx={{
                            bgcolor: "#FFF1F0",
                            color: "#FF4D4F",
                            ...chipStyle,
                            fontSize,
                        }}
                        label="Danger"
                    />
                </Box>
            );

        case "warning":
            return (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Chip
                        variant="light"
                        sx={{
                            bgcolor: "#FFF3DB",
                            color: "#EB8E51",
                            ...chipStyle,
                            fontSize,
                        }}
                        label="Warning"
                    />
                </Box>
            );
        default:
            return (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Chip
                        variant="light"
                        sx={{
                            bgcolor: "#E6F7FF",
                            color: "#1890FF",
                            ...chipStyle,
                            fontSize,
                        }}
                        label="Info"
                    />
                </Box>
            );
    }
}

export const cellDeleteIcon = ({
    value,
    openAlertBox,
}: {
    value: string;
    openAlertBox: (name: string) => void;
}) => {
    return (
        <Stack>
            <IconButton
                onClick={() => {
                    openAlertBox(value);
                }}
                color="error"
                sx={{
                    margin: "auto",
                    color: "#FF4D4F",
                    "&:hover": {
                        color: "#FF4D4F",
                    },
                }}
            >
                <BsTrash style={{ margin: "auto" }} />
            </IconButton>
        </Stack>
    );
};

export function cellBoolean({ value }: any) {
    if (value) {
        return (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
                {"Yes"}
            </Box>
        );
    } else {
        return (
            <Box sx={{ display: "flex", justifyContent: "center" }}>{"No"}</Box>
        );
    }
}

export function cellUpdateTld({ value }: any) {
    const url: string = `/admin/tlds/update/${value}`;
    return (
        <Box sx={{ display: "flex", justifyContent: "left" }}>
            <Link to={url} style={{ textDecoration: "none", color: "#1890FF" }}>
                {value}
            </Link>
        </Box>
    );
}

export function cellCenterAlign({ value }: { value: string }) {
    return <div style={{ textAlign: "center" }}>{value}</div>;
}

export function cellRightAlign({ value }: { value: string }) {
    return <div style={{ textAlign: "right" }}>{value}</div>;
}

export function cellPreviewBalance({ value }: { value: string | number }) {
    return (
        <div style={{ textAlign: "right" }}>{new Big(value).toFixed(2)}</div>
    );
}

export function cellAuctionStatus({ value }: { value: string }) {
    const statusMap: {
        [key: string]: {
            bgcolor: string;
            color: string;
            opacity: string;
            label: string;
        };
    } = {
        Inprogress: {
            bgcolor: "#FFF4D9",
            color: "#FFB700",
            opacity: "100%",
            label: "In Progress",
        },
        Waiting: {
            bgcolor: "#E6F7FF",
            color: "#1890FF",
            opacity: "100%",
            label: "Waiting",
        },
        Success: {
            bgcolor: "#F6FFED",
            color: "#52C41A",
            opacity: "100%",
            label: "Success",
        },
        Failure: {
            bgcolor: "#FFF1F0",
            color: "#FF4D4F",
            opacity: "100%",
            label: "Failure",
        },
        Paused: {
            bgcolor: "#EBDFEF",
            color: "#A76BC7",
            opacity: "100%",
            label: "Paused",
        },
        "Paused&Archived": {
            bgcolor: "#EBDFEF",
            color: "#A76BC7",
            opacity: "100%",
            label: "Paused",
        },
        Cancelled: {
            bgcolor: "#F0F0F0",
            color: "#3A3541",
            opacity: "60%",
            label: "Cancelled",
        },
        Archived: {
            bgcolor: "#F0F0F0",
            color: "#3A354199",
            opacity: "100%",
            label: "Archived",
        },
    };

    const { bgcolor, color, opacity, label } =
        statusMap[value] || statusMap.Waiting;

    return (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Chip
                variant="light"
                sx={{
                    bgcolor,
                    color,
                    px: 0.1,
                    py: 0,
                    borderRadius: "8px",
                    opacity: opacity,
                }}
                label={label}
            />
        </Box>
    );
}

export const cellAuctionTypeStatus = ({ value, row }: any) => {
    return cellAuctionTypeChip(value, row?.original?.isArchived);
};

export function cellAuctionTypeChip(value: any, isArchived: any) {
    if (!isArchived) {
        switch (value) {
            case AuctionStatusEnum.ONGOING:
                return (
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <Chip
                            variant="light"
                            sx={{
                                bgcolor: "#FFF4D9",
                                color: "#FFB700",
                                ...chipStyle,
                                fontSize,
                            }}
                            label="Ongoing"
                        />
                    </Box>
                );
            case AuctionStatusEnum.LOCKED:
                return (
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <Chip
                            variant="light"
                            sx={{
                                bgcolor: "#EBDFEF",
                                color: "#A76BC7",
                                ...chipStyle,
                                fontSize,
                            }}
                            label="Locked"
                        />
                    </Box>
                );

            case AuctionStatusEnum.PAYMENT_PENDING:
                return (
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <Chip
                            variant="light"
                            sx={{
                                bgcolor: "#D2F0F7",
                                color: "#2A8EA5",
                                ...chipStyle,
                                fontSize,
                            }}
                            label="Payment Pending"
                        />
                    </Box>
                );
            case AuctionStatusEnum.CANCELLED:
                return (
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <Chip
                            variant="light"
                            sx={{
                                bgcolor: "#FFF1F0",
                                color: "#FF4D4F",
                                ...chipStyle,
                                fontSize,
                            }}
                            label="Cancelled"
                        />
                    </Box>
                );
            case AuctionStatusEnum.CLOSED:
                return (
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <Chip
                            variant="light"
                            sx={{
                                bgcolor: "#EBDFEF",
                                color: "#A76BC7",
                                ...chipStyle,
                                fontSize,
                            }}
                            label="Closed"
                        />
                    </Box>
                );
            case AuctionStatusEnum.DEFAULT:
                return (
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <Chip
                            variant="light"
                            sx={{
                                bgcolor: "#EBEAEA",
                                color: "#000",
                                ...chipStyle,
                                fontSize,
                            }}
                            label="Default"
                        />
                    </Box>
                );
            default:
                return <></>;
        }
    } else {
        return (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Chip
                    variant="light"
                    sx={{
                        bgcolor: "#F0F0F0",
                        color: "#838087",
                        ...chipStyle,
                        fontSize,
                    }}
                    label="Archived"
                />
            </Box>
        );
    }
}

export const cellDomainStatus = ({ value }: { value: DomainStatusEnum }) => {
    try {
        return (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
                <DomainTypeStatus size="medium" status={value} />
            </Box>
        );
    } catch (error) {
        return <></>;
    }
};

export const cellDeleteIconWithID = ({
    value,
    id,
    openAlertBox,
}: {
    value: string;
    id: number;
    openAlertBox: (name: string, id: number) => void;
}) => {
    return (
        <Stack>
            <IconButton
                onClick={() => {
                    openAlertBox(value, id);
                }}
                color="error"
                sx={{
                    margin: "auto",
                    color: "#FF4D4F",
                    "&:hover": {
                        color: "#FF4D4F",
                    },
                }}
            >
                <BsTrash style={{ margin: "auto" }} />
            </IconButton>
        </Stack>
    );
};

export const cellDateAndTimeNormal = ({ value }: any) => {
    if (!value) return <></>;
    else {
        const dateTimeFromEpoch = DateTime.fromSeconds(value);
        return (
            <div style={{ textAlign: "center" }}>
                {dateTimeFromEpoch.toFormat("yyyy-MM-dd HH:mm:ss")}
            </div>
        );
    }
};

export const cellBigNumber = ({ value }: any) => {
    if (!value) return <></>;
    else {
        const bigNumber = Big(value).toFixed(2);
        return <div style={{ textAlign: "right" }}>{bigNumber}</div>;
    }
};
