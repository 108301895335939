import {
    Box,
    Button,
    IconButton,
    InputAdornment,
    OutlinedInput,
    TextField,
    Typography,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useEffect, useState } from "react";
import largeLogo from "../assets/images/autobackorder_logo_login.svg";
import { formStyles } from "../pages/auth/LoginPageStyles";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useFormik } from "formik";
import useAuth from "../hooks/useAuth";
import { messageType } from "../types/contextTypes";
import SnackbarMessage from "../components/SnackbarMessage";
import SpinnerLoader from "../components/loader/loader";

const LoginForm = () => {
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [message, setMessage] = useState<messageType>({
        message: "",
        type: "info",
    });
    const [open, setOpen] = useState<boolean>(false);
    const handleClickShowPassword = () => {
        setShowPassword((showPassword) => !showPassword);
    };
    const auth = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const initialMessage = location.state?.message;
    const initMessageType = location.state?.type;

    const validationSchema = yup.object({
        username: yup.string().required("Username is required"),
        password: yup.string().required("Password is required"),
    });

    function showMessage(type: "info" | "error", message: string) {
        setMessage({ message, type });
        setOpen(true);
    }

    const formik = useFormik({
        initialValues: {
            username: "",
            password: "",
        },
        validationSchema,
        async onSubmit(values) {
            setLoading(true);
            console.log(values);
            try {
                await auth.login(values.username, values.password);
                navigate("/allBids");
            } catch (e: any) {
                if (e.response?.status === 401) {
                    showMessage("error", e.response.data.message);
                } else if (e.response?.status === 500) {
                    showMessage("error", "Internal server error");
                } else if (e.message === "disabled") {
                    showMessage(
                        "error",
                        "Your account has been disabled. Please contact " +
                            process.env.REACT_APP_DEFAULT_USER_EMAIL,
                    );
                } else if (e.message === "notAdmin") {
                    showMessage("error", "User not authorized");
                } else {
                    showMessage("error", "User login failed");
                }
            } finally {
                setLoading(false);
            }
        },
    });

    useEffect(() => {
        let timeout: NodeJS.Timeout;
        if (initMessageType == "passwdChange" && initialMessage) {
            timeout = setTimeout(() => {
                setMessage({ message: initialMessage, type: "success" });
                setOpen(true);
                window.history.replaceState({}, document.title);
            }, 500);
        }
        return () => clearTimeout(timeout);
    }, []);

    return (
        <Box sx={formStyles.container}>
            <SnackbarMessage
                message={message.message}
                type={message.type}
                open={open}
                setOpen={setOpen}
            />
            <Box sx={formStyles.responsiveLogoContainer}>
                <img
                    src={largeLogo}
                    style={{
                        width: "inherit",
                        height: "inherit",
                        margin: "auto",
                    }}
                />
            </Box>
            <Box
                sx={{
                    py: 4,
                }}
            >
                <Typography sx={formStyles.formTitle}>Sign In</Typography>
            </Box>
            <Box>
                <Box
                    sx={{
                        width: "100%",
                    }}
                >
                    <form
                        onSubmit={formik.handleSubmit}
                        style={{
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <Typography sx={formStyles.formLabel}>
                            Username
                        </Typography>
                        <TextField
                            id="username"
                            error={
                                !!formik.errors.username &&
                                formik.touched.username
                            }
                            name="username"
                            // placeholder="johndoe"
                            inputProps={{
                                style: {
                                    padding: "14px",
                                    fontSize: "14px",
                                    backgroundColor: "white",
                                },
                            }}
                            value={formik.values.username}
                            onChange={(e) => {
                                formik.setFieldValue(
                                    "username",
                                    e.target.value.toLowerCase().trim(),
                                );
                            }}
                        />
                        {formik.errors.username && formik.touched.username && (
                            <Typography
                                sx={{ ...formStyles.formWarn, mt: "1px" }}
                            >
                                {formik.errors.username}
                            </Typography>
                        )}
                        <Typography
                            sx={{
                                ...formStyles.formLabel,
                                mt: 3,
                            }}
                        >
                            Password
                        </Typography>
                        <OutlinedInput
                            error={
                                !!formik.errors.password &&
                                formik.touched.password
                            }
                            inputProps={{
                                style: {
                                    padding: "14px",
                                },
                            }}
                            sx={{ bgcolor: "white", borderColor: "red" }}
                            id="password"
                            name="password"
                            // placeholder="********"
                            type={showPassword ? "text" : "password"}
                            value={formik.values.password}
                            onChange={(e) => {
                                formik.setFieldValue(
                                    "password",
                                    e.target.value.trim(),
                                );
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        // onMouseDown={handleClickShowPassword}
                                        edge="end"
                                    >
                                        {showPassword ? (
                                            <VisibilityIcon />
                                        ) : (
                                            <VisibilityOffIcon />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                        <Box
                            sx={{
                                my: 1,
                                display: "flex",
                                justifyContent: "space-between",
                                mt: "4px",
                            }}
                        >
                            {formik.errors.password &&
                            formik.touched.password ? (
                                <Typography sx={formStyles.formWarn}>
                                    {formik.errors.password}
                                </Typography>
                            ) : (
                                <Typography></Typography>
                            )}
                            <Link
                                to="/forgotPassword"
                                style={{ textDecoration: "none" }}
                            >
                                <Typography sx={formStyles.formLink}>
                                    Forgot password?
                                </Typography>
                            </Link>
                        </Box>
                        <Button
                            type="submit"
                            sx={formStyles.formButton}
                            disabled={loading}
                            endIcon={
                                <SpinnerLoader
                                    loading={loading}
                                    size={20}
                                    sx={{ marginLeft: "10px" }}
                                />
                            }
                        >
                            Sign In
                        </Button>
                    </form>
                </Box>
            </Box>
        </Box>
    );
};

export default LoginForm;
